<template>
  <div class="opportunities">
    <div class="container">
      <ul class="category-nav" style="margin-top: 0">
        <li v-for="nav in navList" :key="nav.name">
          <a href="javascript:;" :class="{ active: nav.investmentType == currentType }" @click="changeCategory(nav)">{{ getText(nav, 'name') }}</a>
        </li>
      </ul>
      <div class="table opportunities-table">
        <el-table :data="list" style="width: 100%" :row-class-name="getRowClass" default-expand-all>
          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="pdf-list" style="margin-left: 178px">
                <ul v-if="props.row.investChanceFileList.length > 0">
                  <li v-for="report in props.row.investChanceFileList" :key="report.fileName">
                    <div class="link">
                      <div class="name">{{ report.fileName }}</div>
                    </div>
                    <div>
                      <a :href="report.fileUrl" target="_blank" download class="el-button el-button--primary el-button--mini">{{ $t('fileDownload') }}</a>
                      <el-button type="primary" size="mini" @click="toPdf(report.fileUrl)">{{ $t('ViewReport') }}</el-button>
                    </div>
                  </li>
                </ul>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" :label="$t('Date')" width="120"> </el-table-column>
          <el-table-column :label="$t('ProjectName')" width="180">
            <template slot-scope="scope">{{ getText(scope.row, 'name') }}</template>
          </el-table-column>
          <el-table-column :label="$t('Description')">
            <template slot-scope="scope">{{ getText(scope.row, 'description') }}</template>
          </el-table-column>
          <el-table-column prop="investCurrency" :label="$t('investmentCurrency')"> </el-table-column>
          <el-table-column :label="$t('Industry')" width="160" class-name="industry-column">
            <template slot-scope="scope"
              ><span class="industry-bg" :style="`background-color: ${scope.row.industry.color};`">{{ getText(scope.row.industry, 'name') }}</span></template
            >
          </el-table-column>
          <el-table-column prop="investStage" :label="$t('Stage')" width="140">
            <template slot-scope="scope">
              <span v-if="scope.row.investStage == 'EARLY'">{{ $t('Early') }}</span>
              <span v-if="scope.row.investStage == 'GROWING'">{{ $t('Growth') }}</span>
              <span v-if="scope.row.investStage == 'PRE_IPO'">Pre-IPO</span>
              <span v-if="scope.row.investStage == 'IPO'">{{ $t('IPO') }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column :label="$t('fileDownload')" width="140">
            <template slot-scope="scope">
              <el-popover placement="bottom" width="314" trigger="click" v-if="scope.row.investChanceFileList.length > 0">
                <div class="download-list">
                  <ul>
                    <li v-for="report in scope.row.investChanceFileList" :key="report.fileName">
                      <a :href="report.fileUrl" target="_blank" download>
                        <div class="name">{{ report.fileName }}</div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div slot="reference" class="table-download"><img src="../assets/icon-download.svg" class="icon-download" /></div>
              </el-popover>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      navList: [
        {
          name: '已立项项目',
          enName: 'Live Opportunities',
          investmentType: 'CREATE',
        },
        {
          name: '可跟投项目机会',
          enName: 'Portfolio Opportunities',
          investmentType: 'FOLLOW',
        },
      ],
      currentType: 'CREATE',
      tableData: [],
      list: [],
      files: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      api
        .getInvestChance({
          investmentType: this.currentType,
        })
        .then((res) => {
          this.list = res.data
        })
    },
    getFiles(id) {
      api.getFiles(id).then((res) => {
        this.files = res.data
      })
    },
    changeCategory(nav) {
      this.currentType = nav.investmentType
      this.getList()
    },
    getRowClass(row, rowIndex) {
      //判断当前行是否有子数据
      if (row.row.investChanceFileList.length == 0) {
        return 'row-expand-cover'
      }
    },
  },
}
</script>

<style>
.el-table .row-expand-cover .cell .el-table__expand-icon {
  display: none;
}
</style>

<style lang="scss" scoped>
.table-download {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
